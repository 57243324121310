import React from 'react';
import styled from "styled-components";

import Layout from '../components/theme/layout';
import Seo from '../components/seo';

const QiwioPink = '#ff5679'

const HeroContainer = styled.div`
    padding: 30px;

    @media screen and (min-width: 501px) {
        text-align: center;
        padding: 50px;
    }

    h1 {
        font-size: 1.625rem;
        font-family: 'Barlow Semi Condensed';
        font-weight: 700;
        margin-bottom: 30px;

        @media screen and (min-width: 501px) {
            font-size: 2.5rem;
        }
    }

    p {
        font-family: 'barlow';
        font-size: 1rem;
        font-weight: 400;

        @media screen and (min-width: 501px) {
            font-size: 1.25rem;
            max-width: 80%;
            margin: 0 auto;
        }
         @media screen and (min-width: 1200px) {
            max-width: 60%;
        }
    }
`
const SalesContactFormWrapper = styled.div`
    padding: 0 30px 100px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

     @media screen and (min-width: 900px) {
        padding: 0 30px 100px 50px;
     }

    input,
    select,
    textarea {
        width: 100%;
        background-color: #272731;
        border: none;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 10px;
        color: white;

         @media screen and (min-width: 1100px) {
            width: 49.5%;

            &:nth-child(3),
            &:nth-child(5) {
                margin-right: 1%;
            }

            &:nth-child(7) {
                width: 100%;
            }
        }

        &::placeholder {
            font-family: 'Barlow Semi Condensed';
            font-size: 1.125rem;
            font-weight: 600;
            color: rgba(255, 255, 255, .6);
        }

         &:focus {
            outline: 1px solid #ff5679;
        }
    }

    textarea {
         @media screen and (min-width: 900px) {
            width: 100%;
        }
    }

    label {
        font-family: 'Barlow Semi Condensed';
        color: white;
        display: block;
        margin-bottom: 10px;
    }

    button {
        background-color: ${QiwioPink};
        border: 1px solid ${QiwioPink};
        color: white;
        margin-top: 10px;
        width: 100%;
        padding: 12px;
        border-radius: 4px;
        font-size: 1rem;
        font-family: 'Barlow Semi Condensed';
        font-weight: 600;
        color: white;
        cursor: pointer;
        transition: all .4s;

        &:hover {
            transform: translateY(-4px)
        }
    }
`
const SelectWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    div {
        width: 49.5%;

        select {
            width: 100%;
        }
    }
`

export default function ContactSalesPage() {
  return (
      <Layout>
        <Seo title='Contact Sales' />
        <HeroContainer>
            <h1>Looking for a solution that fits your specific business needs?</h1>
            <p>Our sales team can answer your questions, provide product demos, and create custom solutions that fit your needs. We can’t wait to hear what you’re working on.</p>
        </HeroContainer>

        <SalesContactFormWrapper>
            <form method='post' netlify-honeypot='bot-field' data-netlify='true' name='sales contact form'>
                <input type="hidden" name='bot-field' />
                <input type="hidden" name='form-name' value='sales contact form' />

                <input type="text" name="firstName" id="firstName" placeholder='First name *' required/>
                <input type="text" name="lastName" id="lastName" placeholder='Last name *' required/>
                <input type="email" name="email" id="email" placeholder='Your business email *' required/>
                <input type="tel" name="phone" id="phone" placeholder='Phone number *' required/>
                <input type="text" name="company" id="company" placeholder='Company name *' required/>
                <SelectWrapper>
                    <div>
                        <label htmlFor="companySize">Company size *</label>
                        <select name="companySize" id="companySize">
                            <option value="0-10">0-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-100">51-100</option>
                            <option value="101-250">101-250</option>
                            <option value="251-500">251-500</option>
                            <option value="500+">500+</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="industry">Industry *</label>
                        <select name="industry" id="industry">
                            <option value="eCommerce">eCommerce</option>
                            <option value="Software (Saas)">Software (Saas)</option>
                            <option value="Agency/Consultant">Agency/Consultant</option>
                            <option value="eLearning">eLearning</option>
                            <option value="Education">Education</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </SelectWrapper>
                <textarea name="message" id="message" cols="30" rows="5" placeholder='How can our sales team help? *' required></textarea>
                <button>Give us a shout</button>
            </form>
        </SalesContactFormWrapper>

      </Layout>
  )
}
